import { createContext } from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const AppContext = createContext({
  bgColor: 'orange.100',
  color: 'orange.800',
})

export function AppProvider (props) {

  return (
    <AppContext.Provider value={{
      bgColor: useColorModeValue('orange.50', 'gray.800'),
      color: useColorModeValue('orange.900', 'orange.50'),
    }}>
      {props.children}
    </AppContext.Provider>
  )
}
