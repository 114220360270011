import {
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import LandingHero from './LandingHero'

function LandingPage () {
  return (
    <VStack spacing={8} flex={1} width={'100%'}>
      <LandingHero />
    </VStack>
  )
}

export default LandingPage
