import {
  VStack,
  Flex, Text, useColorModeValue, createIcon,
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { WakeCallLogo } from './AppContainer'
import { Helmet } from 'react-helmet'
import { AppContext } from '../AppProvider'

function LandingContainer () {

  const {bgColor, color} = useContext(AppContext)

  return (
    <VStack spacing={8} flex={1} width={'100%'}>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Wake Call | Home</title>
      </Helmet>
      {/* <Flex backgroundImage={null} bgSize={"cover"} height={"40vh"} */}
      {/*           display={"flex"} flex={1} */}
      {/*      minHeight={"40vh"} width={"100%"} */}
      {/* > */}
      {/* </Flex> */}

      <Outlet/>

      <VStack
        width="100%"
        minHeight="10vh"
        py="30px"
        px={['40px', '60px', '100px', '200px']}
        bg={bgColor}
        color={color}
        align="start"
        spacing={6}
      >
        <Flex
          flexDir={['column', 'column', 'row']}
          width="100%"
          h={['300px', '300px']}
          spacing={6}
          justify={'space-between'}
          align={['center', 'center', 'start']}
        >

          <WakeCallLogo/>
          {/*<VStack>*/}
          {/*  <Heading size="sm">ABOUT</Heading>*/}
          {/*  <Link href="/our-story">Our Story</Link>*/}
          {/*</VStack>*/}
          {/*<VStack>*/}
          {/*  <Heading size="sm">TERMS</Heading>*/}
          {/*  <Link href="/privacy-policy">Privacy Policy</Link>*/}
          {/*  <Link href="/terms">Terms</Link>*/}
          {/*</VStack>*/}

        </Flex>

        <VStack width="100%" justify="center" align="center">

          <Text>
            Copyright ©
            {new Date().getFullYear()}
            {' '}
            | Wake Call
          </Text>
          <Text>Built with ☕️️ | All rights reserved.</Text>
        </VStack>

      </VStack>
    </VStack>
  )
}

export default LandingContainer
